import { NoSsr } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StandardDivProps } from '@sprinx/react-mui-components/StandardDiv';
import clsx from 'clsx';
import React from 'react';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import AppHeader from './components/AppHeader';
import MainLayoutCustomerNotification from './components/MainLayoutCustomerNotification';
import MainHorisontalNavigation from './components/MainHorisontalNavigation';

export interface MainLayoutProps extends StandardDivProps<MainLayoutClassKey> {
  aside?: React.ReactElement;
  children?: React.ReactNode;
  className?: string;
}

export type MainLayoutClassKey = 'root' | 'content';

const useStyles = makeStyles(
  (theme) =>
    createStyles<MainLayoutClassKey, {}>({
      root: {
        backgroundColor: theme.palette.background.default,
        marginTop: theme.spacing(20),
      },
      content: {
        marginBottom: theme.spacing(8),
        flexGrow: 1,
        maxWidth: '100%',
        boxSizing: 'border-box',
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(1),
        },
        [theme.breakpoints.up('xl')]: {
          marginRight: theme.spacing(36),
        },
      },
    }),
  { name: 'MainLayout' },
);

const MainLayout: React.FC<MainLayoutProps> = ({ children = null, classes: pClasses, className, aside, ...rest }) => {
  const classes = useStyles({ classes: pClasses });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <AppHeader />
      <MainHorisontalNavigation />

      <main className={classes.content}>{children}</main>
      {aside}
      <NoSsr>
        <ErrorBoundary>
          <MainLayoutCustomerNotification />
        </ErrorBoundary>
      </NoSsr>
    </div>
  );
};

MainLayout.displayName = 'MainLayout';

export default MainLayout;
