import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import type { Theme } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import { productTaxonomyTypesQuery } from '../../../api/products/taxonomyTypes';
import useRouteUrlFactory from '../../../hooks/useRouteUrlFactory';
import useTranslateWithFallback from '../../../hooks/useTranslateWithFallback';

export type MainHorisontalNavigationProps = {
  classes?: Partial<Record<MainHorisontalNavigationClassKey, string>>;
};

export type MainHorisontalNavigationClassKey =
  | 'root'
  | 'category'
  | 'categoryIcon'
  | 'categoryText'
  | 'selectedCategory';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<MainHorisontalNavigationClassKey, {}>({
    root: {
      position: 'fixed',
      top: theme.spacing(10),
      zIndex: theme.zIndex.drawer + 1,
      width: '100%',
      backgroundColor: '#ed1c24',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: theme.spacing(2),
      padding: theme.spacing(0, 2),
      [theme.breakpoints.down('sm')]: {
        gap: theme.spacing(0.5),
        padding: theme.spacing(0, 1),
      },
    },
    category: {
      height: theme.spacing(10),
      width: theme.spacing(14),
      gap: theme.spacing(0.5),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: theme.spacing(1),
      color: '#fff',
      fontWeight: 600,

      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(8),
        width: theme.spacing(10),
      },

      '&:hover': {
        backgroundColor: '#f26065',
      },
    },
    categoryIcon: {
      maxWidth: '90%',
      height: theme.spacing(3),
    },
    categoryText: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    selectedCategory: {
      backgroundColor: '#f26065',
    },
  }),
);

export default function MainHorisontalNavigation({ classes: pClasses }: MainHorisontalNavigationProps): JSX.Element {
  const classes = useStyles({ classes: pClasses });
  const routeUrlFactory = useRouteUrlFactory();
  const t = useTranslateWithFallback();
  const taxonomies = useRecoilValue(productTaxonomyTypesQuery);
  const { params } = useRouteMatch<{ categories: string }>();

  return (
    <div className={classes.root}>
      {taxonomies
        .filter((x) => x.extra.depth === 2)
        .map((node) => {
          const updatedNode = node.code.replace('/catalogue/', '');
          return (
            <RouterLink
              className={clsx(classes.category, {
                [classes.selectedCategory]: params.categories === updatedNode,
              })}
              key={updatedNode}
              to={routeUrlFactory('categories', { params: { categories: updatedNode } })}
            >
              <img
                className={classes.categoryIcon}
                src={`/storex/navigation/${updatedNode.split('/').slice(-1)}.svg`}
                alt={updatedNode}
              />
              <div className={classes.categoryText}>{t(node.label)}</div>
            </RouterLink>
          );
        })}
    </div>
  );
}
