import deepOrange from '@material-ui/core/colors/deepOrange';
import { ThemeOptions } from '@material-ui/core';
import { ApplicationSettings } from './@sprinx/react-after-razzle';
import { SupportedLocale } from './i18n/types';
import { RouteUrlType } from './routesBuilder';

type StringRecord = Partial<Record<SupportedLocale, string>> & { default: string };

export type DocumentFile = {
  alt: StringRecord;
  href?: string;
  hrefProps?: {
    options?: {
      otherParamsToQs?: boolean | string[];
      params?: Record<string, string | string[]>;
    };
    type: RouteUrlType;
  };
  src: StringRecord;
  title: StringRecord;
};

type Link = {
  href: string;
  label: StringRecord;
};

export type DocumentFileNames = 'bulkImportDocument' | 'homepageMainImage';

export type MultipleDocumentFileNames = 'downloadedDocuments' | 'homepageCarousel' | 'homepageImageSection';

type StaticContentTypeKebabCase =
  | 'homepage-main-info-box'
  | 'homepage-secondary-info-box'
  | 'business-conditions'
  | 'gdpr'
  | 'footer-contact-box'
  | 'footer-headquarters';

type CustomerProfileNavigationItem =
  | 'customerProfile'
  | 'customerProfileOrders'
  | 'customerProfileInvoices'
  | 'customerProfileCreditNotes'
  | 'customerProfileInvoicesAndCreditNotes'
  | 'customerProfileDocuments'
  | 'customerProfileDeliveryNotes'
  | 'customerProfileShoppingLists'
  | 'cartsApproval';

type ModuleConfig = {
  activeManufacturersFilter: boolean;
  customerProfileNavigationItems: CustomerProfileNavigationItem[];
  customNavigationLinks: Link[] | null;
  files: Record<DocumentFileNames, DocumentFile | null>;
  monitorStockAvailability: boolean;
  multipleFiles: Record<MultipleDocumentFileNames, DocumentFile[] | null>;
  productDetailAuthenticatedParameterCodes: string[];
  productDetailGlobalParameters: string[] | null;
  showContactPage: boolean;
  showDownloadDocuments: boolean;
  showExternalDocumentsDetail: boolean;
  showGdpr: boolean;
  showProductDetailSpagettyNavigation: boolean;
  showStockAvailability: boolean;
  showTermsAndConditions: boolean;
  staticContentTypes: StaticContentTypeKebabCase[];
  themeOptions: ThemeOptions;
} & Pick<ApplicationSettings, 'supportedCurrencies' | 'supportedLocales' | 'pricesType'>;

type EnvConfig = {
  projectName: string;
  projectNameUpperCase: string;
  rumApiKey: string | undefined;
} & Pick<ApplicationSettings, 'currency' | 'locale' | 'region' | 'anonymouseUserId'>;

type Config = ApplicationSettings & EnvConfig & ModuleConfig;

const allConfigs: Record<string, ModuleConfig> = {
  storex: {
    activeManufacturersFilter: false,
    productDetailAuthenticatedParameterCodes: [],
    productDetailGlobalParameters: null,
    showProductDetailSpagettyNavigation: false,
    customerProfileNavigationItems: [
      'customerProfile',
      'customerProfileOrders',
      'customerProfileInvoices',
      'customerProfileCreditNotes',
      'customerProfileDeliveryNotes',
      'customerProfileShoppingLists',
      'cartsApproval',
    ],
    showExternalDocumentsDetail: false,
    supportedCurrencies: ['CZK'],

    supportedLocales: ['cs'],
    pricesType: 'B2B',

    customNavigationLinks: null,
    monitorStockAvailability: false,
    showStockAvailability: false,
    showContactPage: true,
    showTermsAndConditions: true,
    showDownloadDocuments: false,
    showGdpr: true,
    themeOptions: {
      palette: {
        primary: {
          light: '#042751',
          main: '#042751',
          dark: '#282F49',
          contrastText: '#ffffff',
        },
        secondary: deepOrange,
        appBar: {
          background: '#970000',
          color: {
            contrastText: '#FFF',
            logo: '#000000',
          },
        },
        mainNavigation: {
          background: '#D8D8D8',
          backgroundActive: '#000000',
        },
        mainNavigationContent: {
          background: '#606060',
        },
        background: {
          default: '#dfdfdf',
        },
        appFooter: {
          background: '#970000',
          color: '#OOO',
        },
      },
    },
    files: {
      bulkImportDocument: {
        src: {
          en: '/bulkImportDocuments/bulk_import_en.csv',
          cs: '/bulkImportDocuments/hromadny_import_cz.csv',
          default: '/bulkImportDocuments/bulk_import_en.csv',
        },
        alt: {
          en: 'Bulk import template',
          cs: 'Šablona pro hromadný import',
          default: 'Bulk import template',
        },
        title: {
          en: 'Bulk import template',
          cs: 'Šablona pro hromadný import',
          default: 'Bulk import template',
        },
      },
      homepageMainImage: null,
    },
    multipleFiles: {
      downloadedDocuments: null,
      homepageCarousel: [
        {
          src: {
            en: '/homepage/DC2-1024x683.jpg',
            cs: '/homepage/DC2-1024x683.jpg',
            default: '/homepage/DC2-1024x683.jpg',
          },
          alt: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          title: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          hrefProps: {
            type: 'product',
            options: {
              params: { sn: 'med-comfort-high-risk-rukavice', id: '6603e1e479b33d001b587ec8' },
            },
          },
        },
        {
          src: {
            en: '/homepage/ULH6-1024x683.jpg',
            cs: '/homepage/ULH6-1024x683.jpg',
            default: '/homepage/ULH6-1024x683.jpg',
          },
          alt: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          title: {
            en: 'Banner - karusel 2',
            cs: 'Banner - karusel 2',
            default: 'Banner - karusel 2',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a3' } },
          },
        },
        // {
        //   src: {
        //     en: '/homepage/pneumatika.png',
        //     cs: '/homepage/pneumatika.png',
        //     default: '/homepage/pneumatika.png',
        //   },
        //   alt: {
        //     en: 'Banner - karusel 3',
        //     cs: 'Banner - karusel 3',
        //     default: 'Banner - karusel 3',
        //   },
        //   title: {
        //     en: 'Banner - karusel 3',
        //     cs: 'Banner - karusel 3',
        //     default: 'Banner - karusel 3',
        //   },
        //   hrefProps: {
        //     type: 'categories',
        //     options: { params: { categories: 'a4' } },
        //   },
        // },
      ],
      homepageImageSection: [
        {
          src: {
            en: '/homepage/scs7-768x357.png',
            cs: '/homepage/scs7-768x357.png',
            default: '/homepage/scs7-768x357.png',
          },
          alt: {
            en: 'Info banner - Action',
            cs: 'Info banner - Akce',
            default: 'Info banner - Action',
          },
          title: {
            en: 'Info banner - Action',
            cs: 'Info banner - Akce',
            default: 'Info banner - Action',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a1' } },
          },
        },
        {
          src: {
            en: '/homepage/scs7-768x357.png',
            cs: '/homepage/scs7-768x357.png',
            default: '/homepage/scs7-768x357.png',
          },
          alt: {
            en: 'Info banner - warehouse stock sale',
            cs: 'Info banner - výprodej skladových zásob',
            default: 'Info banner - warehouse stock sale',
          },
          title: {
            en: 'Info banner - warehouse stock sale',
            cs: 'Info banner - výprodej skladových zásob',
            default: 'Info banner - warehouse stock sale',
          },
          hrefProps: {
            type: 'categories',
            options: { params: { categories: 'a2' } },
          },
        },
      ],
    },
    staticContentTypes: [
      'homepage-main-info-box',
      'homepage-secondary-info-box',
      'business-conditions',
      'gdpr',
      'footer-contact-box',
      'footer-headquarters',
    ],
  },
};

const getModuleConfig = (projectName: string) => allConfigs[projectName] || {};

if (!process.env.RAZZLE_PROJECT_NAME) {
  console.error('PROJECT_NAME is not defined');
}

export const knownProjects = Object.keys(allConfigs);

const getProjectName = (name: string | undefined) => name || 'default';

if (!knownProjects.includes(getProjectName(process.env.RAZZLE_PROJECT_NAME))) {
  console.error(
    `PROJECT_NAME "${getProjectName(process.env.RAZZLE_PROJECT_NAME)}" is not known. Known projects are:`,
    knownProjects.join(', '),
  );
}

const getConfig = (projectName: string): Config => ({
  projectName: projectName,
  projectNameUpperCase: projectName.toUpperCase(),
  rumApiKey: process.env.RAZZLE_RUM_API_KEY,
  currency: process.env.RAZZLE_SX_DEFAULT_CURRENCY || 'CZK',
  locale: (process.env.RAZZLE_SX_LOCALE as SupportedLocale) || 'cs',
  region: process.env.RAZZLE_SX_REGION || '_default',
  // TODO: add to AppProvider
  anonymouseUserId: process.env.RAZZLE_SX_ANONYMOUS_CONTACT_ID || 'anonymouse',
  ...getModuleConfig(projectName),
});

export const {
  // region: old application settings
  supportedCurrencies,
  supportedLocales,
  pricesType,
  currency,
  locale,
  region,
  anonymouseUserId,

  // region: newly added settings
  projectName,
  projectNameUpperCase,
  customNavigationLinks,
  showExternalDocumentsDetail,
  rumApiKey,
  files,
  multipleFiles,
  monitorStockAvailability,
  productDetailAuthenticatedParameterCodes,
  productDetailGlobalParameters,
  showStockAvailability,
  showContactPage,
  showProductDetailSpagettyNavigation,
  showTermsAndConditions,
  showDownloadDocuments,
  showGdpr,
  themeOptions,
  staticContentTypes,
  customerProfileNavigationItems,
  activeManufacturersFilter,
} = getConfig(getProjectName(process.env.RAZZLE_PROJECT_NAME));
