import { usePreferredLocale } from '@sprinx/react-globalize';
import { SupportedLocale } from '../i18n/types';
import { routeUrl, RouteUrlType } from '../routesBuilder';

type RouteUrlFactory = (
  type: RouteUrlType,
  options?: { otherParamsToQs?: boolean | string[]; params?: Record<string, string> },
) => string;

const useRouteUrlFactory = (): RouteUrlFactory => {
  const locale = usePreferredLocale() as SupportedLocale;

  return (
    type: RouteUrlType,
    options: {
      otherParamsToQs?: boolean | string[];
      params?: Record<string, string>;
    } = {},
  ) => {
    const { params, otherParamsToQs } = options;

    // For Storex there is no root category, so we need to redirect to the first category
    const url =
      type === 'catalogue'
        ? routeUrl('categories', {
            locale,
            params: { ...params, categories: 'osobni-auta' },
            otherParamsToQs,
          })
        : routeUrl(type, {
            locale,
            params: params ?? {},
            otherParamsToQs,
          });

    return url;
  };
};

export default useRouteUrlFactory;
